import React, {FunctionComponent} from 'react';
import {PageProps} from '../types';
import {Layout} from '../components/Layout';
import SEO from '../components/SEO';
import {PageWrapper} from '../styles/page';
import PageWidth from '../components/PageWidth';
import {BlogThumbnail} from '@focusritegroup/web-ui/';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {BlogTemplateQuery} from './__generated__/BlogTemplateQuery';
import {getLocalImageFromFragment} from '../utils';

interface BlogHub extends PageProps<BlogTemplateQuery> {}

export const BlogThumbnails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-start;

  @media screen and (min-width: 840px) {
    > * {
      margin-right: 20px;
    }
  }
`;

const BlogHub: FunctionComponent<BlogHub> = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query BlogTemplateQuery {
          en: allContentfulAmpifyWebBlogPost(
            filter: {
              node_locale: {eq: "en-GB"}
              pageTitle: {ne: null}
              metaDescription: {ne: null}
              metaLinkLabel: {ne: null}
            }
          ) {
            edges {
              node {
                slug
                metaKeywords
                metaDescription
                metaLinkLabel
                pageTitle
                headerImage {
                  ...AmpifyHeaderImage
                }
              }
            }
          }
        }
      `}
      render={(data: BlogTemplateQuery) => {
        const blogPosts = data.en.edges.map(({node}) => node);
        return (
          <Layout>
            <SEO title="Ampify Hub" />
            <PageWrapper>
              <PageWidth wide>
                <BlogThumbnails data-test-id="blog-thumbnails-wrapper">
                  {blogPosts.map(
                    ({
                      slug,
                      metaDescription,
                      metaLinkLabel,
                      pageTitle,
                      headerImage,
                    }) => (
                      <BlogThumbnail
                        key={slug}
                        link={{
                          label: metaLinkLabel,
                          href: `/the-hub/${slug}`,
                        }}
                        image={() => (
                          <Img
                            fluid={getLocalImageFromFragment(headerImage)}
                            style={{width: '100%', height: '100%'}}
                          />
                        )}
                        title={pageTitle}
                        body={{text: metaDescription, maxLength: 150}}
                      />
                    )
                  )}
                </BlogThumbnails>
              </PageWidth>
            </PageWrapper>
          </Layout>
        );
      }}
    ></StaticQuery>
  );
};

export default BlogHub;
